<template>
  <div id="TypeOfWork" class="pageContol">
    >
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">评价工种</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 查询 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="考试工种" class="searchboxItem ci-full">
              <span class="itemLabel">考试工种:</span>
              <el-input
                v-model="compOccupationName"
                placeholder="请输入工种名称"
                clearable
                size="small"
              ></el-input>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handleAddEdit('add')"
              >发布工种</el-button
            >
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="工种"
                align="left"
                prop="compOccupationName"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训等级"
                align="left"
                prop="occupationLevel"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.occupationLevel }}级
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.createTime || "--"
                }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleAddEdit('edit', scope.row.compOccupationId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDelete(scope.row.compOccupationId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <!-- <Empty slot="empty" /> -->
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <!-- 发布工种 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      @close="TypeOfWorkCancel"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a" :style="addList.length > 3 ? 'height: 600px' : ''">
        <el-form
          ref="ruleDialogForm"
          :model="ruleDialogForm"
          :rules="rules"
          label-width="150px"
          style="width: 90%"
        >
          <el-form-item label="工种名称" prop="occupationId">
            <el-select
              v-model="ruleDialogForm.occupationId"
              clearable
              size="small"
              filterable
              placeholder="请选择工种"
            >
              <el-option
                v-for="(item, index) in occupationList"
                :key="index"
                :label="item.occupationName"
                :value="item.occupationId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="培训等级" prop="occupationLevel">
            <el-select
              v-model="ruleDialogForm.occupationLevel"
              placeholder="请选择"
              size="small"
              clearable
            >
              <el-option
                v-for="item in ExaminationLevelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="df form-box" v-for="(item, index) in addList" :key="index">
          <el-form :model="item" ref="cardForm" label-width="150px">
            <el-form-item :label="'申报条件' + (index + 1) + ':'">
              <el-input
                size="small"
                placeholder="请输入申报条件"
                clearable
                v-model="item.conditionName"
              ></el-input>
            </el-form-item>
            <el-form-item :label="'需要上传的材料' + (index + 1) + ':'">
              <el-select
                v-model="item.conditionCollection"
                placeholder="请选择"
                size="small"
                multiple
              >
                <el-option
                  v-for="items in MaterialList"
                  :key="items.materialsId"
                  :label="items.materialsName"
                  :value="items.materialsId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="df zsBtn">
            <i
              class="el-icon-circle-plus iconStyle"
              v-if="index == addList.length - 1"
              @click="certificate"
            ></i>
            <i
              class="el-icon-remove iconStyle"
              v-if="addList.length > 1"
              @click="delCertificate(index)"
            ></i>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="TypeOfWorkCancel">取 消</el-button>
        <el-button
          @click="TypeOfWorkSure('ruleDialogForm')"
          class="bgc-bv"
          type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";

export default {
  components: {
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // ----------------  验证 -------------------------
      compOccupationName: "", // 考试工种
      //考试级别
      ExaminationLevelList: [],

      // ----------------  验证 -------------------------

      ruleDialogForm: {
        occupationId: "",
        occupationLevel: "",
      },
      rules: {
        occupationId: {
          required: true,
          message: "请选择工种名称",
          trigger: "change",
        },
        occupationLevel: {
          required: true,
          message: "请选择培训等级",
          trigger: "change",
        },
      },
      dialogTitle: "", //
      dialogVisible: false,
      TypeOfWorkStu: "add", //判断工种状态新增还是修改
      addList: [], // 新增列表
      addObj: {
        conditionName: "", //职业证书编号
        conditionCollection: [], //培训类型id
      },
      MaterialList: [],
      occupationList: [], //可用工种数据
    };
  },
  created() {
    this.getData();
    this.getEvaluate();
    // this.addList.push({ ...this.addObj });
  },
  methods: {
    //码值获取等级
    getEvaluate() {
      const evaluate = this.$setDictionary("EVALUATE_OCCUPATION_LEVEL", "list");
      for (const key in evaluate) {
        this.ExaminationLevelList.push({
          value: key,
          label: evaluate[key],
        });
      }
    },
    //码值获取上传材料
    getMaterialsCode() {
      this.$post("/biz/evaluate/materials/root/list").then((ret) => {
        this.MaterialList = ret.data || [];
      });
    },
    // 获取工种列表
    getOccupationList() {
      this.$post("/biz/evaluate/company/occupation/usable", {
        pageNum: 0,
        pageSize: 0,
      })
        .then((res) => {
          this.occupationList = res.data.list || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      // 鉴定考试工种id
      if (this.compOccupationName) {
        params.compOccupationName = this.compOccupationName;
      }
      this.doFetch({
        url: "/biz/evaluate/company/occupation/pageList",
        params,
        pageNum,
      });
    },
    // 新增||编辑 -- 工种
    handleAddEdit(stu, compOccupationId = "") {
      this.getOccupationList();
      this.getMaterialsCode();
      this.TypeOfWorkStu = stu;
      this.compOccupationId = compOccupationId;
      this.dialogVisible = true;
      if (stu == "add") {
        this.dialogTitle = "新建工种";
        this.addList.push({ ...this.addObj });
      } else {
        this.dialogTitle = "编辑工种";
        this.getInfo(compOccupationId);
      }
    },
    getInfo(compOccupationId) {
      this.$post("/biz/evaluate/company/occupation/getInfo", {
        compOccupationId,
      }).then((ret) => {
        this.ruleDialogForm = {
          occupationId: ret.data.occupationId,
          occupationLevel: ret.data.occupationLevel,
        };
        ret.data.conditionList.map((el) => {
          el.conditionCollection = el.conditionCollection.split(",");
        });
        this.addList = ret.data.conditionList || [];
      });
    },
    //新建 || 编辑工种 -- 确定
    TypeOfWorkSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.addList.length; i++) {
            if (
              this.addList[i].conditionName == "" ||
              !this.addList[i].conditionCollection.length
            ) {
              this.$message({
                message: "申报条件和所需上传材料不能为空",
                type: "error",
              });
              return false;
            }
          }
          let params = {
            occupationId: this.ruleDialogForm.occupationId,
            occupationLevel: this.ruleDialogForm.occupationLevel,
          };
          this.addList.map((el) => {
            el.compOccupationId = this.compOccupationId;
            el.conditionCollection = el.conditionCollection.toString();
          });
          params.conditionList = this.addList;
          if (this.TypeOfWorkStu == "edit") {
            params.compOccupationId = this.compOccupationId;
          }
          this.$post(
            this.TypeOfWorkStu == "edit"
              ? "/biz/evaluate/company/occupation/modify"
              : "/biz/evaluate/company/occupation/insert",
            params
          ).then((res) => {
            if (res.status == 0) {
              this.TypeOfWorkCancel();
              this.getData(-1);
              this.$message({
                type: "success",
                message: "新增成功",
              });
            } 
          }).catch(() => {
            this.addList.map(el => {
              el.conditionCollection = el.conditionCollection.split(',')
            })
            //  this.addList.push({ ...this.addObj });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      })
    },
    //新建 || 编辑工种 -- 取消
    TypeOfWorkCancel() {
      this.$refs["ruleDialogForm"].clearValidate();
       this.dialogVisible = false;
      this.ruleDialogForm = {
        occupationId: "",
        occupationLevel: "",
      };
      this.addList = [];
    },
    // 设置申请条件 --新增
    certificate() {
      this.addList.push({ ...this.addObj });
    },
    // 设置申请条件 --删除
    delCertificate(ind) {
      this.addList.splice(ind, 1);
    },
    //删除
    handleDelete(compOccupationId) {
      this.doDel({
        url: "/biz/evaluate/company/occupation/delete",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { compOccupationId },
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scope>
#TypeOfWork {
  .zsBtn {
    margin-left: 20px;
    button {
      height: 30px;
    }
  }
  .iconStyle {
    font-size: 2rem;
    color: #6676ff;
    cursor: pointer;
  }
  .form-box {
    .el-form {
      width: 90%;
    }
  }
}
</style>
